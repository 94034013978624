import React from "react";
import {TextInput} from "@mantine/core";
import Errors from "./Errors";

type Props = {
    input: any,
    label?: string,
    meta?: any
}

function FieldText(props: Props) {
    const {input, label, meta} = props;

    console.log(meta)

    return (
        <div>
            <TextInput label={label} {...input} />
            <Errors meta={meta} />
        </div>
    )
}

export default FieldText