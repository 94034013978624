import React from "react";
import {Checkbox} from "@mantine/core";

type Props = {
    input: {
        value: boolean,
        onChange: Function
    },
    label?: string
}

function FieldCheckbox(props: Props) {
    const {input, label} = props;
    return (
        <Checkbox checked={input.value} label={label} onChange={event => input.onChange(event.currentTarget.checked)} />
    )
}

export default FieldCheckbox