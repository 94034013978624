import React from "react";
import {useParams} from "react-router-dom";
import {useGetAccountByTokenQuery, useSaveCustomerMutation} from "../../common/api";
import {Button, Loader} from "@mantine/core";
import {Field, Form} from "react-final-form";
import FieldText from "../../components/input/FieldText";
import FieldWrapper from "../../components/input/FieldWrapper";
import FieldCheckbox from "../../components/input/FieldCheckbox";
import validate from "../../common/validate";

type Props = {}

let rules : any = {
    first_name: 'required',
    last_name: 'required',
    address: 'required',
    area: 'required|size:2',
    zip: 'required|size:5',
    city: 'required',
    social_id: 'required',
    email: 'email',
    mobile_phone: 'numeric'
}

function Registration(props: Props) {
    const {} = props;

    const {token} = useParams()

    // @ts-ignore
    const {data: account, isLoading, isSuccess} = useGetAccountByTokenQuery(token)

    const [saveCustomer, saveResult] = useSaveCustomerMutation()

    if (account && account.required_consents) {
        account.required_consents.forEach((cons: any) => {
            rules[`consent_${cons.id}`] = 'accepted'
        })
    }
    if (account && account.config.require_email) {
        rules.email = 'required|email'
    }
    if (account && account.config.require_phone) {
        rules.mobile_phone = 'required|numeric'
    }

    const validation = validate(rules)

    if (isLoading) {
        return (
            <div>
                {
                    isLoading && (
                        <div style={{marginTop: 200, textAlign: 'center'}}>
                            <Loader />
                        </div>
                    )
                }
            </div>
        )
    }

    if (saveResult.isSuccess) {
        return (
            <div style={{margin: 20}}>
                <h3>I dati sono stati registrati correttamente!</h3>
            </div>
        )
    }

    if (isSuccess) {
        return (
            <div style={{margin: 15}}>
                <h2>{account.name}</h2>
                <h3>Registrazione cliente</h3>
                {
                    account.config.intro ?
                        <p>{account.config.intro}</p> : null
                }
                <Form onSubmit={saveCustomer} initialValues={{
                    token
                }} validate={validation}>
                    {
                        ({values, handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <FieldWrapper>
                                    <Field name={'first_name'} label={'Nome'} component={FieldText} />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={'last_name'} label={'Cognome'} component={FieldText} />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={'social_id'} component={FieldText} label={'Codice fiscale'} />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={'address'} component={FieldText} label={'Indirizzo'} />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={'city'} component={FieldText} label={'Città'} />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={'zip'} component={FieldText} label={'CAP'} />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={'area'} component={FieldText} label={'Provincia'} />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={'email'} component={FieldText} label={'Email'} />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={'mobile_phone'} component={FieldText} label={'Cellulare'} />
                                </FieldWrapper>

                                <div style={{marginBottom: 10}}>
                                    <a href={account.privacy_policy_url} target={'_blank'}>Consenso trattamento dati</a>
                                    {
                                        account.required_consents.map((consent: any) => (
                                            <FieldWrapper>
                                                <Field name={`consent_${consent.id}`} component={FieldCheckbox} label={consent.consent_granted} labelNoTrans />
                                            </FieldWrapper>
                                        ))
                                    }
                                </div>

                                <Button type={'submit'} loading={saveResult.isLoading}>Salva</Button>
                            </form>
                        )
                    }
                </Form>
            </div>
        )
    }

    return null;
}

export default Registration