import * as Validator from 'validatorjs';

// @ts-ignore

const messages = {
    "accepted": "Devi accettare le condizioni d'uso per continuare",
    "alpha": "Il campo :attribute deve contenere solo caratteri alfabetici.",
    "alpha_dash": "Il campo :attribute può contenere solo caratteri alfanumerici oltre a trattini e trattini bassi.",
    "alpha_num": "Il campo :attribute deve essere alfanumerico.",
    "between": {
        "numeric": "Il campo :attribute deve essere compreso tra :min e :max.",
        "string": "Il campo :attribute deve essere compreso tra :min e :max caratteri"
    },
    "confirmed": "Il campo conferma :attribute non è uguale.",
    "email": "Il formato dell'attributo :attribute non è valido.",
    "def": "Gli attributi del campo :attribute contengono degli errori.",
    "digits": "Il campo :attribute deve essere di :digits cifre.",
    "digits_between": "Il campo :attribute deve essere tra :min e :max cifre.",
    "different": "Il campo :attribute e :different devo essere diversi.",
    "integer": "Il campo :attribute deve essere un valore numerico intero.",
    "min": {
        "numeric": "Il campo :attribute deve essere maggiore o uguale a :min.",
        "string": "Il campo :attribute deve essere composto da almeno :min caratteri"
    },
    "max": {
        "numeric": "Il campo :attribute deve essere minore o uguale a :max.",
        "string": "Il campo :attribute deve essere composto da massimo :max caratteri"
    },
    "not_in": "Il campo :attribute non è valido.",
    "numeric": "Il campo :attribute deve essere un numero.",
    "required_if": "Il campo :attribute é obbligatorio",
    "required_without": "Il campo :attribute è richiesto quando il campo :field è vuoto.",
    "required_without_all": "Il campo :attribute è richiesto quando il campo :field è vuoto.",
    "required_with": "Il campo :attribute è richiesto quando il campo :field è presente.",
    "same": "I campi :attribute e :same devono essere uguali.",
    "size": {
        "numeric": "La dimensione del campo :attribute deve essere uguale a :size.",
        "string": "Il campo :attribute deve essere di :size caratteri"
    },
    "string": "Il campo :attribute deve essere una stringa.",
    "url": "Il formato del campo :attribute non è valido.",
    "regex": "Il formato del campo :attribute non è valido.",
    "required": "Il campo :attribute è obbligatorio",
    "required_without.first_name": "Inserisci Nome e Cognome o Ragione sociale",
    "required_without.last_name": "Inserisci Nome e Cognome o Ragione sociale",
    "required_without_all.business_name": "Inserisci Nome e Cognome o Ragione sociale",
    "required_with.inv_business_name": "Il campo :attribute è obbligatorio",
    "required_with.inv_address": "Il campo :attribute è obbligatorio",
    "required_with.inv_city": "Il campo :attribute è obbligatorio",
    "required_with.inv_zip": "Il campo :attribute è obbligatorio",
    "required_with.inv_social_id": "Il campo :attribute è obbligatorio",
    "required_with.inv_vat_id": "Il campo :attribute è obbligatorio",
    "pwd_format_error": "La password deve contenere almeno un numero e una lettera maiuscola"
}

const attributes = {
    'first_name': 'Nome',
    'last_name': 'Cognome',
    'social_id': 'Codice fiscale',
    'address': 'Indirizzo',
    'city': 'Città',
    'zip': 'CAP',
    'area': 'Provincia',
    'email': 'Email',
    'mobile_phone': 'Cellulare'
}

export default (rules) => {
    return (data) => {
        let validation = new Validator(data, rules, messages);
        
        validation.setAttributeNames(attributes)
        validation.passes(); // true
        validation.fails(); // false
        
        return validation.errors.all()
    }
}
