import React from "react";

type Props = {
    children: any
}

function FieldWrapper(props: Props) {
    const {children} = props;
    return (
        <div style={{marginTop: 0, marginBottom: 10}}>{children}</div>
    )
}

export default FieldWrapper