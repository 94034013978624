import React from 'react';
import './App.css';
import { store } from './common/store'
import { Provider } from 'react-redux'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Registration from "./features/registration/Registration";

const router = createBrowserRouter([
    {
        path: "/reg/:token",
        element: <Registration />,
    },
]);


function App() {
  return (
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
  );
}

export default App;
